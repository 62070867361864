<template>
  <b-button type="submit" :class="buttonClasses" :variant="buttonStyle" :disabled="loading">
    {{ content }}
    <b-spinner variant="white" class="ms-1" small v-if="loading"></b-spinner>
    <span class="sr-only">Loading...</span>
  </b-button>
</template>

<script>

export default {
  name: 'ButtonLoader',
  props: {
    content: String,
    buttonStyle: String,
    buttonClass: String,
    loading: Boolean,
  },
  computed: {
    buttonClasses() {
      return `btn--disabled-alt-style ${this.buttonClass}`;
    },
  },
};
</script>
